<template>
    <section class="h-auto" id="schedule">
        <div class="flex flex-col lg:flex-row justify-start">
            <div class="logo-bg w-full lg:w-[25%]">
                <div class="flex justify-center lg:pt-32">
                    <img src="../assets/seham-logo.png" alt="seham-logo">
                </div>
            </div>
            <div class="cards-bg w-full lg:w-[75%]">
                <div class="flex flex-wrap justify-center  gap-10 p-10">
                    <a href="https://kick.com/Sehamx" target="_blank">
                        <img src="../assets/friday.png" alt="friday">
                    </a>
                    <a href="https://stake.com/?offer=seham&c=cOV6GvSE" target="_blank">
                        <img src="../assets/exclusive.png" alt="exclusive">
                    </a>
                    <a href="https://stake.us/?offer=seham&c=cOV6GvSE" target="_blank">
                        <img src="../assets/sign-up.png" alt="sign-up">
                    </a>
                    <a href="https://discord.gg/seham" target="_blank">
                        <img src="../assets/join-discord.png" alt="discord">
                    </a>
                    <a href="https://streamlabs.com/xSeham1/tip" target="_blank">
                        <img src="../assets/donate.png" alt="donate">
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.logo-bg{
  background-image: url('../assets/logo-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.cards-bg{
  background-image: url('../assets/cards-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
