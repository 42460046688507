<template>
    <section class="social-bg" id="socials">
        <div class="flex flex-col lg:flex-row justify-center items-center gap-12 px-10 py-8 md:gap-12 md:px-24 md:py-16 lg:gap-72 lg:px-52 lg:py-16">
            <div class="text-white text-3xl md:text-5xl font-black uppercase [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white" style="font-family: Open Sans, sans-serif;">
                Follow Me
            </div>
            <div class="flex flex-wrap lg:flex-col justify-start md:justify-center gap-8" style="font-family: Open Sans, sans-serif;">
                <a href="https://x.com/xSehamm" target="_blank">
                    <div class="flex flex-row items-center gap-2">
                        <img src="../assets/twitter.png" alt="twitter">
                        <span class="text-white font-bold [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">twitter</span>
                    </div>
                </a>
                <a href="https://www.instagram.com/xSehamm" target="_blank">
                    <div class="flex flex-row items-center gap-2">
                        <img src="../assets/instagram.png" alt="instagram">
                        <span class="text-white font-bold [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">instagram</span>
                    </div>
                </a>
                <a href="https://www.youtube.com/@Sehamx" target="_blank">
                    <div class="flex flex-row items-center gap-2">
                        <img src="../assets/youtube.png" alt="youtube">
                        <span class="text-white font-bold [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">youtube</span>
                    </div>
                </a>
                <a href="https://kick.com/Sehamx" target="_blank">
                    <div class="flex flex-row items-center gap-2">
                        <img src="../assets/kick.png" alt="kick">
                        <span class="text-white font-bold [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">kick</span>
                    </div>
                </a>
                <a href="https://discord.gg/seham" target="_blank">
                    <div class="flex flex-row items-center gap-2">
                        <img src="../assets/discord.png" alt="discord">
                        <span class="text-white font-bold [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">discord</span>
                    </div>
                </a>
                <div class="flex flex-row items-center gap-2">
                    <img src="../assets/tiktok.png" alt="tiktok">
                    <span class="text-white font-bold [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">tiktok</span>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.social-bg{
  background-image: url('../assets/social-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
