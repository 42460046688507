<template>
  <!-- Leaderboard Section  -->
  <section class="h-auto">
    <div class="flex justify-center items-center px-4 md:px-8 lg:pt-0">
      <div
        class="flex flex-col justify-center items-center p-8 rounded-3xl">
        <div class="flex flex-row justify-center items-center">
          <!-- <img v-motion-pop-visible src="../assets/leaderboard.png" class="px-5" alt="leaderboard"> -->
        </div>
        <!--
        <div v-motion-pop-visible class="flex justify-center text-center text-xl text-[#FFD700] px-2 mt-4 lg:mt-10 uppercase font-base tracking-wider" style="font-family: Secular One, sans-serif;">roobet leaderboard ends every week. <br> ONLY THE TOP 15 ARE REWARDED. YOU WILL BE REWARDED VIA ROOBET <br> SO MAKE SURE TO SIGN UP UNDER CODE: MIK</div>
        <div v-motion-pop-visible class="text-center text-xl text-[#FFD700] px-2 mt-4 lg:mt-2 uppercase font-base tracking-wider" style="font-family: Secular One, sans-serif;">All wagers are counted during the inclusive dates:
              <span class="text-white">{{ roobetStartDate }} - {{ roobetEndDate }}</span>
              <br>Leaderboard ends every Sunday 11: 59:59PM EST. 
              <br>New leaderboard data will be available by Monday 12:00 AM EST
        </div> -->
        <div v-if="loading == true" class="text-center text-white text-xl md:text-4xl mt-10 font-bold" style="font-family: Russo One, sans-serif;">Loading Leaderboard...</div>
        <div v-if="loading == false"  class="hidden md:block">
          <div class="flex flex-col justify-center items-center">
            <div class="bg-gradient-to-r from-[#003013] via-[#000902] to-[#003013]">
              <div v-motion-slide-visible-bottom class="text-center text-base text-white font-thin uppercase px-10 py-3" style="font-family: Russo One, sans-serif;">
                {{ selectedLeaderboard == false ? timer : "Leaderboard Ended" }}
              </div>
            </div>
            <div class="hidden md:block">
              <label class="toggle-switch mt-8 bg-gradient-to-r from-[#140000] to-[#002216]" style="font-family: Russo One, sans-serif;">
                <input type="checkbox" @change="changeLeaderboard()" v-model="selectedLeaderboard"/>
                <div class="slider border border-white"></div>
                <span class="left-label text-white">ACTIVE</span>
                <span class="right-label text-white">ENDED</span>
              </label>
            </div>
          </div>
          <div class="flex flex-col lg:flex-row justify-center items-center gap-6 lg:gap-10 mt-5 leaderboard-platform-bg lg:px-28 lg:pt-20 lg:pb-[298px]">
            <div v-motion-pop-visible class="md:flex lg:hidden flex-col items-center lg:-mt-44">
              <div class="bg-gradient-to-r from-[#00220A] via-[#3A3F34] to-[#00220A] shadow-2xl p-3 w-80 h-40" style="font-family: Russo One, sans-serif;">
                <div class="flex flex-row">
                  <div class="flex flex-col justify-center items-center gap-2 w-5 [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                    <div class="text-[#00C2FF] text-4xl font-semibold">
                      1
                    </div>
                    <div class="text-[#00C2FF] text-4xl font-semibold">
                      S
                    </div>
                    <div class="text-[#00C2FF] text-4xl font-semibold">
                      T
                    </div>
                  </div>
                  <div class="flex flex-col justify-start items-center ml-16 gap-2">
                      <div class="text-[#00C2FF] text-2xl font-bold tracking-widest [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                        player #101
                      </div>
                      <div class="text-[#00C2FF] text-xs font-light uppercase [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                        wagered: $1,500,000
                      </div>
                      <div class="text-[#00FF47] text-5xl font-bold uppercase mt-5">
                        $1500
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-motion-pop-visible class="flex flex-col items-center lg:mt-52">
              <div class="bg-gradient-to-r from-[#00220A] via-[#3A3F34] to-[#00220A] shadow-2xl p-3 w-80 h-40" style="font-family: Russo One, sans-serif;">
                <div class="flex flex-row">
                  <div class="flex flex-col justify-center items-center gap-2 w-5 [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                    <div class="text-[#00C2FF] text-4xl font-semibold">
                      2
                    </div>
                    <div class="text-[#00C2FF] text-4xl font-semibold">
                      N
                    </div>
                    <div class="text-[#00C2FF] text-4xl font-semibold">
                      D
                    </div>
                  </div>
                  <div class="flex flex-col justify-start items-center ml-16 gap-2">
                      <div class="text-[#00C2FF] text-2xl font-bold tracking-widest [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                        {{ top3.length > 0 ? maskString(top3[1].user_name) : '---' }}
                      </div>
                      <div class="text-[#00C2FF] text-xs font-light uppercase [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                        wagered: {{ top3.length > 0 ? top3[1].wagered : '---' }}
                      </div>
                      <div class="text-[#00FF47] text-5xl font-bold uppercase mt-5">
                        {{ selectedLeaderboard == false ? getCurrentRewardValue(1) : getPrevRewardValue(1)}}
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-motion-pop-visible class="md:hidden lg:flex flex-col items-center lg:-mt-44">
              <div class="bg-gradient-to-r from-[#00220A] via-[#3A3F34] to-[#00220A] shadow-2xl p-3 w-80 h-40" style="font-family: Russo One, sans-serif;">
                <div class="flex flex-row">
                  <div class="flex flex-col justify-center items-center gap-2 w-5 [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                    <div class="text-[#00C2FF] text-4xl font-semibold">
                      1
                    </div>
                    <div class="text-[#00C2FF] text-4xl font-semibold">
                      S
                    </div>
                    <div class="text-[#00C2FF] text-4xl font-semibold">
                      T
                    </div>
                  </div>
                  <div class="flex flex-col justify-start items-center ml-16 gap-2">
                      <div class="text-[#00C2FF] text-2xl font-bold tracking-widest [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                        {{ top3.length > 0 ? maskString(top3[0].user_name) : '---' }}
                      </div>
                      <div class="text-[#00C2FF] text-xs font-light uppercase [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                        wagered: {{ top3.length > 0 ? top3[0].wagered : '---' }}
                      </div>
                      <div class="text-[#00FF47] text-5xl font-bold uppercase mt-5">
                        {{ selectedLeaderboard == false ? getCurrentRewardValue(0) : getPrevRewardValue(0)}}
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-motion-pop-visible class="flex flex-col items-center lg:mt-52">
              <div class="bg-gradient-to-r from-[#00220A] via-[#3A3F34] to-[#00220A] shadow-2xl p-3 w-80 h-40" style="font-family: Russo One, sans-serif;">
                <div class="flex flex-row">
                  <div class="flex flex-col justify-center items-center gap-2 w-5 [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                    <div class="text-[#00C2FF] text-4xl font-semibold">
                      3
                    </div>
                    <div class="text-[#00C2FF] text-4xl font-semibold">
                      R
                    </div>
                    <div class="text-[#00C2FF] text-4xl font-semibold">
                      D
                    </div>
                  </div>
                  <div class="flex flex-col justify-start items-center ml-16 gap-2">
                      <div class="text-[#00C2FF] text-2xl font-bold tracking-widest [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                        {{ top3.length > 0 ? maskString(top3[2].user_name) : '---' }}
                      </div>
                      <div class="text-[#00C2FF] text-xs font-light uppercase [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                        wagered: {{ top3.length > 0 ? top3[2].wagered : '---' }}
                      </div>
                      <div class="text-[#00FF47] text-5xl font-bold uppercase mt-5">
                        {{ selectedLeaderboard == false ? getCurrentRewardValue(2) : getPrevRewardValue(2)}}
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center mb:mb-24" :class="selectedLeaderboard == false && prevLeaderboard.length == 0 ? 'lg:-mt-64' : ''">
            <table class="w-full lg:w-auto border-separate border-spacing-y-6">
              <thead v-motion-slide-visible-bottom class="rounded bg-gradient-to-r from-[#00220A] via-[#000000] to-[#00220A] border border-[#20202E]" style="font-family: Russo One, sans-serif;">
                <tr> 
                  <th
                    class="px-1 md:px-[45px] lg:pr-24 lg:pl-8 py-2 md:py-4 text-[#05FF00] text-[18px] md:text-2xl font-light text-left rounded-l [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                    Ranks
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-[#05FF00] text-[18px] md:text-2xl font-light text-center [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                    User
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-[#05FF00] text-[18px] md:text-2xl font-light text-center [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                    Amount
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:pl-24 lg:pr-8 py-2 md:py-4 text-[#05FF00] text-[18px] md:text-2xl font-light text-right rounded-r [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                    Prize
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in topRest" :key="index" style="font-family: Russo One, system-ui" class="rounded bg-gradient-to-r from-[#00220A] via-[#000000] to-[#00220A] border border-[#20202E]">
                  <td
                    class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[20px] font-black text-left uppercase" style="font-family: Russo One, system-ui">
                      <div class="text-[#00C2FF] tracking-[4px] [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">{{ getOrdinalSuffix(index + 4) }}</div>
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[20px] text-[#00C2FF] font-thin text-center tracking-[4px]">
                    {{ maskString(item.user_name) }}
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[20px] text-[#00C2FF] font-thin text-center tracking-[4px]">
                    {{ item.wagered }}
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-8 py-2 md:py-5 whitespace-nowrap text-[20px] text-[#05FF00] font-black text-right tracking-[4px] [text-shadow:2px_2px_20px_var(--tw-shadow-color)] shadow-white">
                    {{ selectedLeaderboard == false ? getCurrentRewardValue(index + 3) : getPrevRewardValue(index + 3)}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="loading == false"  class="block md:hidden">
          <div class="flex flex-col justify-center items-center gap-4">
            <div class="bg-gradient-to-r from-[#003013] via-[#000902] to-[#003013]">
              <div v-motion-slide-visible-bottom class="text-center text-base text-white font-thin uppercase px-10 py-3" style="font-family: Russo One, sans-serif;">
                {{ selectedLeaderboard == false ? timer : "Leaderboard Ended" }}
              </div>
            </div>
            <ul class="flex flex-wrap gap-1 text-lg font-medium text-center text-gray-500" style="font-family: Lalezar, system-ui;;">
                <li @click="selectedLeaderboard = false; changeLeaderboard();" class="rounded-2xl border border-white cursor-pointer" :class="selectedLeaderboard === false ? 'bg-[#0FB97F]' : ''">
                    <div class="inline-block px-4 py-3 text-white">Active</div>
                </li>
                <li @click="selectedLeaderboard = true; changeLeaderboard();" class="rounded-2xl border border-white cursor-pointer" :class="selectedLeaderboard === true ? 'bg-[#0FB97F]' : ''">
                    <div class="inline-block px-4 py-3 text-white">Ended</div>
                </li>
            </ul>
          </div>
          <div class="flex justify-center mt-4 mb:mb-24 text-xl bg-black bg-opacity-40" style="font-family: Lalezar, system-ui">
            <table class="w-full lg:w-auto">
              <thead v-motion-slide-visible-bottom>
                <tr>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[20px] md:text-2xl font-bold text-left">
                    Ranks
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[20px] md:text-2xl font-bold text-center">
                    User
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[20px] md:text-2xl font-bold text-center">
                    Amount
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[20px] md:text-2xl font-bold text-right">
                    Prize
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in topForMobile" :key="index">
                  <td
                    class="md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[17px] text-white font-normal text-left">
                    {{ getOrdinalSuffix(index + 1) }}
                  </td>
                  <td
                    class="md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[17px] text-white font-normal text-center">
                    {{ maskString(item.user_name) }}
                  </td>
                  <td
                    class="md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[17px] text-white font-normal text-center">
                    {{ item.wagered }}
                  </td>
                  <td
                    class="md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[17px] text-white font-normal text-right">
                    {{ selectedLeaderboard == false ? getCurrentRewardValue(index) : getPrevRewardValue(index) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <PrevLeaderboardSection ref="PrevLeaderboardSection"/> -->

</template>

<script>
import axios from "axios";
import moment from "moment-timezone";
// import PrevLeaderboardSection from './PrevLeaderboardSection.vue';

export default {
	components: {
		// PrevLeaderboardSection
	},
	data() {
		return {
			top3: [],
			topRest: [],
			topForMobile: [],

			timer: "",
			selectedLeaderboard: false,

			currentLeaderboard: [],
			prevLeaderboard: [],

			startDate: null,
			endDate: null,

			notFormattedEndDate: null,

			loading: false,
		};
	},
	methods: {
		async init() {
			const baseUrl = process.env.VUE_APP_BASE_API;

			this.loading = true;

			await axios
				.get(`${baseUrl}/api/leaderboard/stake`, {
					headers: {
						"x-api-key": process.env.VUE_APP_X_API_KEY,
					},
				})
				.then((res) => {
					axios
						.get(`${baseUrl}/api/leaderboard/stake?previous=true`, {
							headers: {
								"x-api-key": process.env.VUE_APP_X_API_KEY,
							},
						})
						.then((rest) => {
							this.startDate = moment(res.data.startDate).format(
								"MMMM DD, YYYY"
							);
							this.endDate = moment(res.data.endDate).format("MMMM DD, YYYY");

							this.notFormattedEndDate = res.data.endDate;

							this.currentLeaderboard = res.data.leaderboard;
							this.prevLeaderboard = rest.data.leaderboard;

							this.changeLeaderboard();
							this.startTimer();

							this.loading = false;
						});
				});
		},
		changeLeaderboard() {
			if (this.selectedLeaderboard == false) {
				const leaderboard = this.currentLeaderboard;
				this.top3 = leaderboard.slice(0, 3).map((item) => {
					return item;
				});

				this.topRest = leaderboard.slice(3, 10).map((item) => {
					return item;
				});

				this.topForMobile = leaderboard.slice(0, 10).map((item) => {
					return item;
				});
			} else {
				const leaderboard = this.prevLeaderboard;
				this.top3 = leaderboard.slice(0, 3).map((item) => {
					return item;
				});

				this.topRest = leaderboard.slice(3, 10).map((item) => {
					return item;
				});

				this.topForMobile = leaderboard.slice(0, 10).map((item) => {
					return item;
				});
			}
		},
		maskString(str) {
			if (!str || str.length <= 4) return str;

			const start = str.slice(0, 2);
			const end = str.slice(-2);
			const middleAsterisks = "*".repeat(str.length - 4);

			return `${start}${middleAsterisks}${end}`;
		},
		getOrdinalSuffix(n) {
			const s = ["th", "st", "nd", "rd"],
				v = n % 100;
			return n + (s[(v - 20) % 10] || s[v] || s[0]);
		},
		getCurrentRewardValue(rank) {
      const prizes = JSON.parse(process.env.VUE_APP_STAKE_CURRENT_LB_PRIZES);
      // if (rank >= 10 && rank < 15) {
      //   return '$'+ 20;
      // }
      // else if(rank >= 15){
      //   return '---'
      // }
      return '$'+ prizes[rank];
    },
    getPrevRewardValue(rank) {
      const prizes = JSON.parse(process.env.VUE_APP_STAKE_PREV_LB_PRIZES);
      // if (rank >= 10 && rank < 15) {
      //   return '$'+ 20;
      // }
      // else if(rank >= 15){
      //   return '---'
      // }
      return '$'+ prizes[rank];
    },
		startTimer() {
			const getNextTargetDate = () => {
				const now = moment().utc();
				let targetDate = moment(`${this.notFormattedEndDate}T00:00:00Z`)
					.utc()
					.subtract(1, "seconds");
				console.log("targetDate: ", targetDate.format());

				if (now.isAfter(targetDate)) {
					targetDate.add(1, "months");
				}

				return targetDate;
			};

			let targetDate = getNextTargetDate();
			let timerInterval;

			const updateTimer = () => {
				const nowEst = moment().utc();
				const distance = targetDate.diff(nowEst);

				if (distance < 0) {
					this.timer = "Leaderboard ended";
					clearInterval(timerInterval);
				} else {
					const duration = moment.duration(distance);
					const days = Math.floor(duration.asDays());
					const hours = duration.hours();
					const minutes = duration.minutes();
					const seconds = duration.seconds();

					this.timer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
				}
			};

			updateTimer();
			timerInterval = setInterval(updateTimer, 1000);
		},
	},
	mounted() {
		this.init();
	},
};
</script>

<style scoped>
.leaderboard-platform-bg {
	background-image: url("../assets/leaderboard-platform-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
}

.toggle-switch {
	position: relative;
	display: inline-flex;
	align-items: center;
	width: 393px;
	height: 56px;
	background: linear-gradient(to right, #140000, #002216);
	padding: 0 8px;
	cursor: pointer;
}

.toggle-switch input {
	position: absolute;
	opacity: 0;
	width: 0;
	height: 0;
}

.toggle-switch .slider {
	position: absolute;
	left: 4px;
	width: 192px;
	height: 48px;
	background: linear-gradient(to right, #004203, #00bd08, #004203);
	transition: transform 0.3s ease;
}

.toggle-switch input:checked + .slider {
	transform: translateX(192px);
}

.toggle-switch span {
	font-size: 20px;
	font-weight: bold;
	z-index: 1;
	padding: 0 5px;
}

.toggle-switch .left-label {
	position: absolute;
	left: 50px;
	margin-top: 5px;
	font-weight: 50;
}

.toggle-switch .right-label {
	position: absolute;
	right: 55px;
	margin-top: 5px;
	font-weight: 50;
}
</style>
